import React from "react";
import ReactDOM from "react-dom";
import "./style/index.scss";
import App from "./components/mainComponents/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
serviceWorkerRegistration.register();
