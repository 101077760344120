import React, { useEffect } from "react";
import "../style/home.scss";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import homeXL from "../img/home1XL.webp";
import homeL from "../img/home1L.webp";
import homeM from "../img/home1M.webp";
import homeS from "../img/home1S.webp";
import homeXS from "../img/home1XS.webp";
import homeXXS from "../img/home1XXS.webp";

function Home(props) {
  useEffect(() => {
    if (props.isKitten) {
      document.querySelectorAll(".kittens__text--middle").forEach((item) => {
        item.classList.remove("kittens__text--invisible");
      });
      document
        .querySelector(".kittens__text--middle")
        .classList.add("kittens__text--visible");
      document.querySelector(".kittens__text--middle").style.textAllign =
        "center";
      document.querySelector(".kittens__text--middle").style.width = "100%";
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.add("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.add("menu__nav-link--yellow");
    } else if (!props.isKitten) {
      document
        .querySelector(".kittens__text--middle")
        .classList.add("kittens__text--invisible");
      document
        .querySelector(".kittens__text--middle")
        .classList.remove("kittens__text--visible");
      document.querySelectorAll(".menu__nav-link--kittens").forEach((item) => {
        item.textContent = "Kittens";
      });
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.remove("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.remove("menu__nav-link--yellow");
    }
  }, [props.isKitten]);

  return (
    <>
      <Helmet>
        <title>
          Home Page | Annanoah Ragdoll Cattery GB - Oxfordshire United Kingdom
        </title>
        <link rel="canonical" href="https://annanoahcattery.com/" />
      </Helmet>
      <main className="home">
        <h1 className="home__title">Welcome to Annanoah Ragdoll Cattery</h1>
        <h2 className="kittens__text kittens__text--middle kittens__text--invisible">
          We have a{" "}
          <NavLink
            to="/kittens"
            exact
            className="kittens__link kittens__link--home kittens__text--middle kittens__text--invisible"
            activeClassName="kittens_active"
          >
            kittens
          </NavLink>{" "}
          !!!
        </h2>
        <div className="home__img-container">
          <picture className="home__picture">
            <source media="(min-width: 1024px)" srcSet={homeXL} />
            <source media="(min-width: 800px)" srcSet={homeL} />
            <source media="(min-width: 600px)" srcSet={homeM} />
            <source media="(min-width: 480px)" srcSet={homeS} />
            <source media="(min-width: 300px)" srcSet={homeXS} />
            <img
              className="home__img"
              src={homeXXS}
              alt="male cat is lying on the carpet annanoah logo"
            />
          </picture>
        </div>
      </main>
    </>
  );
}

export default Home;
