import "../style/catInfo.scss";
import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function CatInfo(props) {
  const history = useHistory();
  useEffect(() => {
    if (props.isKitten) {
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.add("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.add("menu__nav-link--yellow");
    } else if (!props.isKitten) {
      document.querySelectorAll(".menu__nav-link--kittens").forEach((item) => {
        item.textContent = "Kittens";
      });
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.remove("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.remove("menu__nav-link--yellow");
    }
  }, [props.isKitten]);
  return (
    <>
      <Helmet>
        <title>
          Cat Page | Annanoah Ragdoll Cattery GB - Oxfordshire United Kingdom
        </title>
      </Helmet>
      <main className="cat-info">
        <h1 className="cat-info__title">{history.location.state.name}</h1>
        <div className="cat-info__wrapper">
          <h3 className="cat-info__data-title">
            {`${history.location.state.name} Info`}
          </h3>
          <div className="cat-info__info">
            <p className="cat-info__text" style={{ display: "none" }}>
              <span className="cat-info__text-span">
                {history.location.state.description}
              </span>
            </p>
            <p className="cat-info__text">
              EMS CODE:{" "}
              <span className="cat-info__text-span">
                {history.location.state.color}
              </span>
            </p>
            <p className="cat-info__text">
              Date of Birth:{" "}
              <span className="cat-info__text-span">
                {history.location.state.birth}
              </span>
            </p>
            <p className="cat-info__text-main">Parents:</p>
            <p className="cat-info__text">
              Father:{" "}
              <span className="cat-info__text-span">
                {history.location.state.father}
              </span>
            </p>
            <p className="cat-info__text">
              Mother:{" "}
              <span className="cat-info__text-span">
                {history.location.state.mother}
              </span>
            </p>
            <p className="cat-info__text-main">Health:</p>
            <p className="cat-info__text">
              HCM:{" "}
              <span className="cat-info__text-span">
                {history.location.state.HCM}
              </span>
            </p>
            <p className="cat-info__text">
              PKD:{" "}
              <span className="cat-info__text-span">
                {history.location.state.PKD}
              </span>
            </p>
            <p className="cat-info__text">
              Blood gr.:{" "}
              <span className="cat-info__text-span">
                {history.location.state.blood}
              </span>
            </p>
            <p className="cat-info__text">
              FIV:{" "}
              <span className="cat-info__text-span">
                {history.location.state.FIV}
              </span>
            </p>
            <p className="cat-info__text">
              Felv:{" "}
              <span className="cat-info__text-span">
                {history.location.state.Felv}
              </span>
            </p>
          </div>
          <div className="cat-info__img-container">
            <img
              className="cat-info__img cat-info__img-main"
              src={history.location.state.mainPhoto}
              alt={history.location.state.name}
            />
          </div>
        </div>
        <h3 className="cat-info__galery-title">
          {`${history.location.state.name} Gallery`}
        </h3>
        <div className="cat-info__galery-wrapper">
          <img
            className="cat-info__img"
            src={`${history.location.state.photo1}`}
            alt={history.location.state.name}
          />
          <img
            className="cat-info__img"
            src={`${history.location.state.photo2}`}
            alt={history.location.state.name}
          />
          <img
            className="cat-info__img"
            src={`${history.location.state.photo3}`}
            alt={history.location.state.name}
          />
          <img
            className="cat-info__img"
            src={`${history.location.state.photo4}`}
            alt={history.location.state.name}
          />
        </div>
        <div className="cat-info__button-container">
          <button onClick={() => history.goBack()} className="cat-info__button">
            Back
          </button>
        </div>
      </main>
    </>
  );
}

export default CatInfo;
