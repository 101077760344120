import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../style/males.scss";
import "../style/cat.scss";
import { Helmet } from "react-helmet";

const Males = (props) => {
  const [males] = useState([
    {
      name: "Ari Pragdolls*CZ ",
      link: "ari pragdolls*cz ",
      description:
        "Ari is a gentle giant - that is how l would describe him. He has beautiful deep blue eyes and is a very big boy with excellent lines in his pedigree that l know will bring my breeding program to a higher level. Ari came to us from Czech Republic - Prague from the breeder Milada Kratka, once again l want to say a big thank you for your trust and friendship. l can promise you that l will do everything to make sure Ari is well looked after and loved.",
      color: "RAG n 03 Seal Bicolor",
      birth: "21/5/2021",
      father: "MARLCREEK JACE",
      mother: "HARU PRAGDOLLS, CZ",
      HCM: "N/N (-)",
      PKD: "N/N (-)",
      blood: "A",
      FIV: "(-)",
      Felv: "(-)",
      profilePhoto: "assets/male/AriS.webp",
      mainPhoto: "assets/male/AriM.webp",
      photo1: "assets/male/ari2XS.webp",
      photo2: "assets/male/ari3XS.webp",
      photo3: "assets/male/ari4XS.webp",
      photo4: "assets/male/ari5XS.webp",
    },
    /* {
      name: "Fabio Hold Me *PL",
      link: "fabio hold me *pl",
      description:
        "Fabio - my favourite RAG a 04! Fabio is extremely big, well over my expectations but that is nothing to complain about:) Great pedigree, huge potential and his type in my opinion makes it perfect combination. He is very attached to me and follows me everywhere l go , a perfect example ! I strongly believe he will pass his wonderful character to the kittens that are already with the new families!",
      color: "RAG a 04 Ragdoll Blue Mitted",
      birth: "31/12/2020",
      father: "Bailey Violcats*PL",
      mother: "V-Hello Kitty Violcats*PL",
      HCM: "N/N (-)",
      PKD: "N/N (-)",
      blood: "A",
      FIV: "(-)",
      Felv: "(-)",
      profilePhoto: "assets/male/FabioS.webp",
      mainPhoto: "assets/male/FabioM.webp",
      photo1: "assets/male/Fabio1aXS.webp",
      photo2: "assets/male/Fabio2aXS.webp",
      photo3: "assets/male/Fabio3aXS.webp",
      photo4: "assets/male/Fabio4aXS.webp",
    }, */
    {
      name: "CUKIEREK Raggato*PL",
      link: "cukierek raggato",
      description: "",
      color: "RAG n 04 Ragdoll Seal Mitted",
      birth: "16/06/2023",
      father: "MERCURY VELYKINADIYI",
      mother: "PL*RAGGATO GRACJA",
      HCM: "N/N (-)",
      PKD: "N/N (-)",
      blood: "A",
      FIV: "(-)",
      Felv: "(-)",
      profilePhoto: "assets/male/cukierek1S.webp",
      mainPhoto: "assets/male/cukierek1M.webp",
      photo1: "assets/male/cukierek2XS.webp",
      photo2: "assets/male/cukierek3XS.webp",
      photo3: "assets/male/cukierek1XS.webp",
      photo4: "assets/male/cukierek4XS.webp",
    },
    {
      name: "CAN* MARLCREEK TORONTO",
      link: "marlcreek toronto",
      description: "",
      color: "RAG n 03 Ragdoll Seal Bicolour",
      birth: "13/08/2023",
      father: "Mirumkitty Eh Lil Bit Handsome",
      mother: "HMarlcreek AGED 2 PURRFECTION",
      HCM: "N/N (-)",
      PKD: "N/N (-)",
      blood: "A",
      FIV: "(-)",
      Felv: "(-)",
      profilePhoto: "assets/male/mcreek4S.webp",
      mainPhoto: "assets/male/mcreek4M.webp",
      photo1: "assets/male/mcreek2XS.webp",
      photo2: "assets/male/mcreek3XS.webp",
      photo3: "assets/male/mcreek1XS.webp",
      photo4: "assets/male/mcreek4XS.webp",
    },
  ]);

  const history = useHistory();

  function handleClick() {
    history.push();
  }

  useEffect(() => {
    if (props.isKitten) {
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.add("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.add("menu__nav-link--yellow");
    } else if (!props.isKitten) {
      document.querySelectorAll(".menu__nav-link--kittens").forEach((item) => {
        item.textContent = "Kittens";
      });
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.remove("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.remove("menu__nav-link--yellow");
    }
  }, [props.isKitten]);

  const malesList = males.map((cat) => (
    <li className="cat__item" key={cat.name} content={cat}>
      <h2 className="cat__title">{cat.name}</h2>
      <div className="cat__img-wrapper">
        <img
          className="cat__img"
          src={`${cat.profilePhoto}`}
          alt={`${cat.name}`}
        />
      </div>
      <Link
        onClick={handleClick}
        className="cat__link"
        to={{
          pathname: `/cat/${cat.link}`,
          state: {
            name: cat.name,
            description: cat.description,
            color: cat.color,
            birth: cat.birth,
            father: cat.father,
            mother: cat.mother,
            HCM: cat.HCM,
            PKD: cat.PKD,
            blood: cat.blood,
            FIV: cat.FIV,
            Felv: cat.Felv,
            profilePhoto: cat.profilePhoto,
            mainPhoto: cat.mainPhoto,
            photo1: cat.photo1,
            photo2: cat.photo2,
            photo3: cat.photo3,
            photo4: cat.photo4,
          },
        }}
      >
        More info
      </Link>
    </li>
  ));

  return (
    <>
      <Helmet>
        <title>
          Males | Annanoah Ragdoll Cattery GB - Oxfordshire United Kingdom
        </title>
        <link rel="canonical" href="https://annanoahcattery.com/males" />
      </Helmet>
      <main className="males">
        <h1 className="males__title">Males</h1>
        <div className="males__wrapper">
          <ul className="males__list">{malesList}</ul>
        </div>
      </main>
    </>
  );
};

export default Males;
