import Header from "./Header";
import Home from "../Home";
import AboutUs from "../AboutUs";
import CatInfo from "../CatInfo";
import Contact from "../Contact";
import ErrorPage from "../ErrorPage";
import Females from "../Females";
import Footer from "./Footer";
import Kittens from "../Kittens";
import Males from "../Males";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import ScrollButton from "../ScrollButton";
import Litters from "../Litters";
import Image from "../Image";
import React, { useState, useEffect } from "react";
import Ragdoll from "../Ragdoll";
import CookieConsent from "react-cookie-consent";

function App() {
  const [litter] = useState([
    {
      litter: "A",
      father: "Fabio Hold Me *PL",
      mother: "Lopapejsa Beesha*PL",
      motherPhoto: "assets/female/LopapejsaSmall.webp",
      fatherPhoto: "assets/male/Fabio9S.webp",
      active: false,
      kittens: [
        {
          name: "Aspen Annanoah*GB",
          link: "aspen annanoah*gb",
          color: "RAG a Blue Colorpoint",
          sex: "male",
          birth: "16/12/2021",
          HCM: "N/N (-)",
          PKD: "N/N (-)",
          blood: "A",
          FIV: "(-)",
          Felv: "(-)",
          profilePhoto: "assets/kittens/AspenXS.webp",
          mainPhoto: "assets/kittens/AspenXL.webp",
          status: "booked",
        },
        {
          name: "Ava Annanoah*GB",
          link: "ava annanoah*gb",
          color: "RAG a 04 Ragdoll Blue Mitted",
          sex: "female",
          birth: "16/12/2021",
          HCM: "N/N (-)",
          PKD: "N/N (-)",
          blood: "A",
          FIV: "(-)",
          Felv: "(-)",
          profilePhoto: "assets/kittens/AvaXS.webp",
          mainPhoto: "assets/kittens/AvaXL.webp",
          status: "booked",
        },
        {
          name: "Alpin Annanoah*GB",
          link: "alpin annanoah*gb",
          color: "RAG a 04 Ragdoll Blue Mitted",
          sex: "male",
          birth: "16/12/2021",
          HCM: "N/N (-)",
          PKD: "N/N (-)",
          blood: "A",
          FIV: "(-)",
          Felv: "(-)",
          profilePhoto: "assets/kittens/AlpinXS.webp",
          mainPhoto: "assets/kittens/AlpinXL.webp",
          status: "booked",
        },
        {
          name: "Suki Annanoah*GB",
          link: "suki annanoah*gb",
          color: "RAG a Blue Colorpoint",
          sex: "female",
          birth: "16/12/2021",
          HCM: "N/N (-)",
          PKD: "N/N (-)",
          blood: "A",
          FIV: "(-)",
          Felv: "(-)",
          profilePhoto: "assets/kittens/SukiXS.webp",
          mainPhoto: "assets/kittens/SukiXL.webp",
          status: "booked",
        },
        {
          name: "Aqua Annanoah*GB",
          link: "aqua annanoah*gb",
          color: "RAG a 04 Ragdoll Blue Mitted",
          sex: "female",
          birth: "16/12/2021",
          HCM: "N/N (-)",
          PKD: "N/N (-)",
          blood: "A",
          FIV: "(-)",
          Felv: "(-)",
          profilePhoto: "assets/kittens/AquaXS.webp",
          mainPhoto: "assets/kittens/AquaXL.webp",
          status: "booked",
        },
        {
          name: "Amber Annanoah*GB",
          link: "amber annanoah*gb",
          color: "RAG a Blue Colorpoint",
          sex: "female",
          birth: "16/12/2021",
          HCM: "N/N (-)",
          PKD: "N/N (-)",
          blood: "A",
          FIV: "(-)",
          Felv: "(-)",
          profilePhoto: "assets/kittens/AmberXS.webp",
          mainPhoto: "assets/kittens/AmberXL.webp",
          status: "booked",
        },
      ],
    },
    {
      litter: "B",
      father: "Fabio Hold Me *PL",
      mother: "Loombera Beesha*PL",
      motherPhoto: "assets/female/LoomberaSmall.webp",
      fatherPhoto: "assets/male/Fabio9S.webp",
      active: false,
      kittens: [
        {
          name: "Be mine Annanoah*GB",
          link: "be mine annanoah*gb",
          color: "RAG a Blue Colorpoint",
          sex: "female",
          birth: "12/05/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/bemine2XS.webp",
          mainPhoto: "assets/kittens/Bemine2XL.webp",
          status: "booked",
        },
        {
          name: "Belza Annanoah*GB",
          link: "belza annanoah*gb",
          color: "RAG a 03 Ragdoll Blue Bicolor",
          sex: "female",
          birth: "12/05/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/belza2XS.webp",
          mainPhoto: "assets/kittens/Belza2XL.webp",
          status: "booked",
        },
        {
          name: "Bailey Annanoah*GB",
          link: "bailey annanoah*gb",
          color: "RAG a Blue Colorpoint",
          sex: "male",
          birth: "12/05/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/Bailey2XS.webp",
          mainPhoto: "assets/kittens/Bailey2XL.webp",
          status: "booked",
        },
        {
          name: "Brooklyn Annanoah*GB",
          link: "brooklyn annanoah*gb",
          color: "RAG a Blue Colorpoint",
          sex: "male",
          birth: "12/05/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/brooklyn2XS.webp",
          mainPhoto: "assets/kittens/Brooklyn2XL.webp",
          status: "booked",
        },
        {
          name: "Boston Annanoah*GB",
          link: "boston annanoah*gb",
          color: "RAG a Blue Colorpoint",
          sex: "male",
          birth: "12/05/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/boston2XS.webp",
          mainPhoto: "assets/kittens/Boston2XL.webp",
          status: "booked",
        },
        {
          name: "Bueno Annanoah*GB",
          link: "bueno annanoah*gb",
          color: "RAG a 03 Ragdoll Blue Bicolor",
          sex: "female",
          birth: "12/05/2022",
          HCM: "N/N (-)",
          PKD: "N/N (-)",
          blood: "A",
          FIV: "(-)",
          Felv: "(-)",
          profilePhoto: "assets/kittens/bueno2XS.webp",
          mainPhoto: "assets/kittens/Bueno2XL.webp",
          status: "booked",
        },
      ],
    },
    {
      litter: "C",
      father: "Fabio Hold Me *PL",
      mother: "Lopapejsa Beesha*PL",
      motherPhoto: "assets/female/LopapejsaSmall.webp",
      fatherPhoto: "assets/male/Fabio9S.webp",
      active: false,
      kittens: [
        {
          name: "Cala Lily of Annanoah*GB",
          link: "cala lily of annanoah*gb",
          color: "RAG a Blue Colorpoint",
          sex: "female",
          birth: "04/06/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/CalaXS.webp",
          mainPhoto: "assets/kittens/CalaXL.webp",
          status: "booked",
        },
        {
          name: "Chelsea of Annanoah*GB",
          link: "chelsea of annanoah*gb",
          color: "RAG a Blue Colorpoint",
          sex: "female",
          birth: "04/06/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/ChelseaXS.webp",
          mainPhoto: "assets/kittens/ChelseaXL.webp",
          status: "stay with us",
        },
        {
          name: "Chanel of Annanoah*GB",
          link: "chanel of annanoah*gb",
          color: "RAG a 04 Ragdoll Blue Mitted",
          sex: "female",
          birth: "04/06/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/ChanelXS.webp",
          mainPhoto: "assets/kittens/ChanelXL.webp",
          status: "booked",
        },
        {
          name: "Capri of Annanoah*GB",
          link: "capri of annanoah*gb",
          color: "RAG a 04 Ragdoll Blue Mitted",
          sex: "female",
          birth: "04/06/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/CapriXS.webp",
          mainPhoto: "assets/kittens/CapriXL.webp",
          status: "booked",
        },
        {
          name: "Clarence of Annanoah*GB",
          link: "clarence of annanoah*gb",
          color: "RAG a 04 Ragdoll Blue Mitted",
          sex: "male",
          birth: "04/06/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/ClarenceXS.webp",
          mainPhoto: "assets/kittens/ClarenceXL.webp",
          status: "booked",
        },
        {
          name: "Collin of Annanoah *GB",
          link: "Collin of Annanoah *GB",
          color: "RAG a 04 Ragdoll Blue Mitted",
          sex: "male",
          birth: "04/06/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/CollinXS.webp",
          mainPhoto: "assets/kittens/CollinXL.webp",
          status: "booked",
        },
        {
          name: "Clinton of Annanoah*GB",
          link: "clinton of annanoah*gb",
          color: "RAG a Blue Colorpoint",
          sex: "male",
          birth: "04/06/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/ClintonXS.webp",
          mainPhoto: "assets/kittens/ClintonXL.webp",
          status: "booked",
        },
      ],
    },
    {
      litter: "D",
      father: "Fabio Hold Me *PL",
      mother: "Hibaby GISHA",
      motherPhoto: "assets/female/HibabySmall.webp",
      fatherPhoto: "assets/male/Fabio9S.webp",
      active: false,
      kittens: [
        {
          name: "Dollar of Annanoah *GB",
          link: "dollar of annanoah *gb",
          color: "RAG n 03 seal Bicolor",
          sex: "male",
          birth: "09/06/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/dollarXS.webp",
          mainPhoto: "assets/kittens/dollarXL.webp",
          status: "booked",
        },
        {
          name: "Dakota of Annanoah*GB",
          link: "dakota of annanoah*gb",
          color: "RAG a 03 Blue Bicolor ",
          sex: "female",
          birth: "09/06/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/dakotaXS.webp",
          mainPhoto: "assets/kittens/dakotaXL.webp",
          status: "stay with us , our future Queen",
        },
        {
          name: "Dallas of Annanoah *GB",
          link: "dallas of annanoah *gb",
          color: "RAG n 03 seal bicolor",
          sex: "male",
          birth: "09/06/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/dallasXS.webp",
          mainPhoto: "assets/kittens/dallasXL.webp",
          status: "booked",
        },
        {
          name: "Dollen of Annanoah*GB",
          link: "dollen of annanoah*gb",
          color: "RAG n 03 seal bicolor",
          sex: "male",
          birth: "09/06/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/dollenXS.webp",
          mainPhoto: "assets/kittens/dollenXL.webp",
          status: "booked",
        },
        {
          name: "Dolce of Annanoah *GB",
          link: "dolce of annanoah *gb",
          color: "RAG a 04 Blue Mitted",
          sex: "male",
          birth: "09/06/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/dolceXS.webp",
          mainPhoto: "assets/kittens/dolceXL.webp",
          status: "booked",
        },
        {
          name: "Denver of Annanoah *GB",
          link: "denver of annanoah *gb",
          color: "RAG a 04 Blue Mitted",
          sex: "male",
          birth: "09/06/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/denverXS.webp",
          mainPhoto: "assets/kittens/denverXL.webp",
          status: "booked",
        },
        {
          name: "Diego of Annanoah *GB",
          link: "diego of annanoah *gb",
          color: "RAG a 04 Blue Mitted",
          sex: "male",
          birth: "09/06/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/diegoXS.webp",
          mainPhoto: "assets/kittens/diegoXL.webp",
          status: "booked",
        },
      ],
    },
    {
      litter: "E",
      father: "Ari Pragdolls",
      mother: "Daisy Pragdolls",
      motherPhoto: "assets/female/DaisyS.webp",
      fatherPhoto: "assets/male/ari1S.webp",
      active: false,
      kittens: [
        {
          name: "Ellie of Annanoah *GB",
          link: "ellie of annanoah *gb",
          color: "RAG n 04",
          sex: "female",
          birth: "22/10/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/ellieXS.webp",
          mainPhoto: "assets/kittens/ellieXL.webp",
          status: "booked",
        },
        {
          name: "Enzo of Annanoah *GB",
          link: "enzo of annanoah *gb",
          color: "RAG a 04",
          sex: "male",
          birth: "22/10/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/enzoXS.webp",
          mainPhoto: "assets/kittens/enzoXL.webp",
          status: "booked",
        },
        {
          name: "Eddy of Annanoah *GB",
          link: "eddy of annanoah *gb",
          color: "RAG a 03",
          sex: "male",
          birth: "22/10/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/eddyXS.webp",
          mainPhoto: "assets/kittens/eddyXL.webp",
          status: "booked",
        },
        {
          name: "Eizer of Annanoah *GB",
          link: "eizer of annanoah *gb",
          color: "RAG a 03 21",
          sex: "male",
          birth: "22/10/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/eizerXS.webp",
          mainPhoto: "assets/kittens/eizer.webp",
          status: "booked",
        },
        {
          name: "Ebbie of Annanoah *GB",
          link: "ebbie of annanoah *gb",
          color: "RAG a 03",
          sex: "female",
          birth: "22/10/2022",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/ebbieXS.webp",
          mainPhoto: "assets/kittens/ebbieXL.webp",
          status: "booked",
        },
      ],
    },
    {
      litter: "F",
      father: "Fabio Hold Me *PL",
      mother: "Loombera Beesha*PL",
      motherPhoto: "assets/female/LoomberaSmall.webp",
      fatherPhoto: "assets/male/Fabio9S.webp",
      active: false,
      kittens: [
        {
          name: "Finn of Annanoah*GB",
          link: "finn of annanoah *gb",
          color: "RAG a 04 Ragdoll Blue Mitted",
          sex: "male",
          birth: "19/03/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/finn2xs.webp",
          mainPhoto: "assets/kittens/finn2xl.webp",
          status: "booked",
        },
      ],
    },
    {
      litter: "G",
      father: "Fabio Hold Me *PL",
      mother: "Hibaby GISHA",
      motherPhoto: "assets/female/HibabySmall.webp",
      fatherPhoto: "assets/male/Fabio9S.webp",
      active: false,
      kittens: [
        {
          name: "Gaia of Annanoah *GB",
          link: "gaia of annanoah *gb",
          color: "RAG a 04 Ragdoll Blue Mitted",
          sex: "female",
          birth: "5/05/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/gaiaXS.webp",
          mainPhoto: "assets/kittens/gaiaXL.webp",
          status: "booked",
        },
        {
          name: "Gizmo of Annanoah *GB",
          link: "gizmo of annanoah *gb",
          color: "RAG n 03 Seal bicolor ",
          sex: "male",
          birth: "5/5/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/gizmoXS.webp",
          mainPhoto: "assets/kittens/gizmoXL.webp",
          status: "booked",
        },
        {
          name: "Gucci of Annanoah *GB",
          link: "gucci of annanoah *gb",
          color: "RAG a 03 Ragdoll Blue Bicolor ",
          sex: "male",
          birth: "5/5/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/gucciXS.webp",
          mainPhoto: "assets/kittens/gucciXL.webp",
          status: "booked",
        },
        {
          name: "Gianni of Annanoah*GB",
          link: "gianni of annanoah *gb",
          color: "RAG a 03 Ragdoll Blue Bicolor",
          sex: "male",
          birth: "5/5/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/gianniXS.webp",
          mainPhoto: "assets/kittens/gianniXL.webp",
          status: "booked",
        },
        {
          name: "George of Annanoah*GB",
          link: "george of annanoah *gb",
          color: "RAG n 04 Ragdoll Seal Mitted",
          sex: "male",
          birth: "5/5/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/georgexs.webp",
          mainPhoto: "assets/kittens/georgexl.webp",
          status: "booked",
        },
        {
          name: "Gala of Annanoah *GB",
          link: "gala of annanoah *gb",
          color: "RAG n 04 Ragdoll Seal Mitted",
          sex: "female",
          birth: "5/5/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/galaxs.webp",
          mainPhoto: "assets/kittens/galaxl.webp",
          status: "booked",
        },
        {
          name: "Giulia of Annanoah*GB",
          link: "giulia of annanoah *gb",
          color: "RAG n 04 Ragdoll Seal Mitted",
          sex: "female",
          birth: "5/5/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/giuliaxs.webp",
          mainPhoto: "assets/kittens/giuliaxl.webp",
          status: "booked",
        },
      ],
    },
    {
      litter: "H",
      father: "Ari Pragdolls",
      mother: "Daisy Pragdolls",
      motherPhoto: "assets/female/DaisyS.webp",
      fatherPhoto: "assets/male/ari1S.webp",
      active: false,
      kittens: [
        {
          name: "Hamilton of Annanoah*GB",
          link: "hamilton of annanoah *gb",
          color: "RAG a 04 Ragdoll Blue mitted ",
          sex: "male",
          birth: "27/5/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/hamiltonxs.webp",
          mainPhoto: "assets/kittens/hamiltonxl.webp",
          status: "booked",
        },
        {
          name: "Harper of Annanoah*GB",
          link: "harper of annanoah *gb",
          color: "RAG a 03 Ragdoll Blue Bicolor",
          sex: "female",
          birth: "27/5/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/harperxs.webp",
          mainPhoto: "assets/kittens/harperxl.webp",
          status: "booked",
        },
        {
          name: "Hassie of Annanoah*GB",
          link: "hassie of annanoah *gb",
          color: "RAG a 04  21 Ragdoll Blue Mitted Lynx",
          sex: "female",
          birth: "27/5/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/hassiexsjpg.webp",
          mainPhoto: "assets/kittens/hassiexl.webp",
          status: "booked",
        },
        {
          name: "Hannah of Annanoah*GB",
          link: "hannah of annanoah *gb",
          color: "RAG a 03  21 Ragdoll Blue Bicolor Lynx",
          sex: "female",
          birth: "27/5/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/hannahxs.webp",
          mainPhoto: "assets/kittens/hannahxl.webp",
          status: "booked",
        },
        {
          name: "Harry of Annanoah*GB",
          link: "harry of annanoah *gb",
          color: "RAG a 03 Ragdoll Blue Bicolor ( blaze )",
          sex: "male",
          birth: "27/5/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/harryxs.webp",
          mainPhoto: "assets/kittens/harryxl.webp",
          status: "booked",
        },
        {
          name: "Hollie of Annanoah*GB",
          link: "hollie of annanoah *gb",
          color: "RAG n 04 Ragdoll Seal Mitted",
          sex: "female",
          birth: "27/05/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/holliexs.webp",
          mainPhoto: "assets/kittens/holliexl.webp",
          status: "booked",
        },
      ],
    },
    {
      litter: "I",
      father: "Ari Pragdolls",
      mother: "Lopapejsa Beesha*PL",
      motherPhoto: "assets/female/LopapejsaSmall.webp",
      fatherPhoto: "assets/male/ari1S.webp",
      active: false,
      kittens: [
        {
          name: "Indonesia of Annanoah *GB",
          link: "indonesia of annanoah *gb",
          color: "RAG a 04 Ragdoll Blue mitted ",
          sex: "female",
          birth: "14/7/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/indonesiaXS.webp",
          mainPhoto: "assets/kittens/indonesiaXL.webp",
          status: "booked",
        },
        {
          name: "Indiana of Annanoah *GB",
          link: "indiana of annanoah *gb",
          color: "RAG a 04 Ragdoll Blue Mitted",
          sex: "female",
          birth: "14/7/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/indianaXS.webp",
          mainPhoto: "assets/kittens/indianaXL.webp",
          status: "booked",
        },
        {
          name: "Inez of Annanoah *GB",
          link: "inez of annanoah *gb",
          color: "RAG a 04 Ragdoll Blue Mitted",
          sex: "female",
          birth: "14/7/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/inezXS.webp",
          mainPhoto: "assets/kittens/inezXL.webp",
          status: "booked",
        },
        {
          name: "Ibra of Annanoah *GB",
          link: "ibra of annanoah *gb",
          color: "RAG a 03 Ragdoll Blue bicolor",
          sex: "female",
          birth: "14/7/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/ibraXS.webp",
          mainPhoto: "assets/kittens/ibraXL.webp",
          status: "booked",
        },
        {
          name: "Irmin of Annanoah *GB",
          link: "irmin of annanoah *gb",
          color: "RAG n 04 Ragdoll Seal Mitted",
          sex: "male",
          birth: "14/7/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/irminxs.webp",
          mainPhoto: "assets/kittens/irminxl.webp",
          status: "booked",
        },
        {
          name: "Illinois of Annanoah *GB",
          link: "illinois of annanoah *gb",
          color: "RAG n 04 Ragdoll Seal Mitted",
          sex: "male",
          birth: "14/7/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/illinoisxs.webp",
          mainPhoto: "assets/kittens/illinoisxl.webp",
          status: "booked",
        },
      ],
    },
    {
      litter: "J",
      father: "Ari Pragdolls",
      mother: "Gaia’s Garden Trinity*FR",
      motherPhoto: "assets/female/gaia1S.webp",
      fatherPhoto: "assets/male/ari1S.webp",
      active: false,
      kittens: [
        {
          name: "Jerry of Annanoah*GB",
          link: "jerry of annanoah*gb",
          color: "RAG n 03",
          sex: "male",
          birth: "23/9/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/jerryxs.webp",
          mainPhoto: "assets/kittens/jerryxl.webp",
          status: "booked",
        },
        {
          name: "Jersey of Annanoah *GB",
          link: "jersey of annanoah *gb",
          color: "RAG n 03",
          sex: "male",
          birth: "23/9/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/jerseyxs.webp",
          mainPhoto: "assets/kittens/jerseyxl.webp",
          status: "booked",
        },
        {
          name: "Jasmine of Annanoah *GB",
          link: "jasmine of annanoah *gb",
          color: "RAG n 03",
          sex: "female",
          birth: "23/9/2023",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/jasminexs.webp",
          mainPhoto: "assets/kittens/jasminexl.webp",
          status: "stay with us",
        },
      ],
    },
    {
      litter: "K",
      father: "Ari Pragdolls",
      mother: "Daisy Pragdolls",
      motherPhoto: "assets/female/DaisyS.webp",
      fatherPhoto: "assets/male/ari1S.webp",
      active: false,
      kittens: [
        {
          name: "Kenzo of Annanoah*GB",
          link: "kenzo of annanoah*gb",
          color: "RAG a 03 21 Ragdoll Blue Bicolor Lynx",
          sex: "male",
          birth: "10/3/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/kenzoxs.webp",
          mainPhoto: "assets/kittens/kenzoxl.webp",
          status: "booked",
        },
        {
          name: "Kawaii of Annanoah*GB",
          link: "kawaii of annanoah*gb",
          color: "RAG n 04 21 Ragdoll Seal Mitted Lynx",
          sex: "male",
          birth: "10/3/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/kawaiixs.webp",
          mainPhoto: "assets/kittens/kawaiixl.webp",
          status: "booked",
        },
        {
          name: "Kenya of Annanoah*GB",
          link: "kenya of annanoah*gb",
          color: "RAG a 03 Ragdoll Blue Bicolor",
          sex: "female",
          birth: "10/3/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/kenyaxs.webp",
          mainPhoto: "assets/kittens/kenyaxl.webp",
          status: "booked",
        },
        {
          name: "Keycee of Annanoah*GB",
          link: "keycee of annanoah*gb",
          color: "RAG n 04 Ragdoll seal Mitted",
          sex: "female",
          birth: "10/3/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/keyceexs.webp",
          mainPhoto: "assets/kittens/keyceexl.webp",
          status: "booked",
        },
        {
          name: "Finnegan of Annanoah*GB",
          link: "finnegan of annanoah*gb",
          color: "RAG a 03 21 Ragdoll Blue Bicolor Lynx",
          sex: "male",
          birth: "10/3/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/finneganxs.webp",
          mainPhoto: "assets/kittens/finneganxl.webp",
          status: "booked",
        },
        {
          name: "Milo of Annanoah*GB",
          link: "milo of annanoah*gb",
          color: "RAG a 03 21 Ragdoll Blue Bicolor Lynx",
          sex: "male",
          birth: "10/3/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/miloxs.webp",
          mainPhoto: "assets/kittens/miloxl.webp",
          status: "booked",
        },
      ],
    },
    {
      litter: "L",
      father: "Ari Pragdolls",
      mother: "Lopapejsa Beesha*PL",
      motherPhoto: "assets/female/LopapejsaSmall.webp",
      fatherPhoto: "assets/male/ari1S.webp",
      active: false,
      kittens: [
        {
          name: "Lenox of Annanoah*GB",
          link: "lenox of annanoah*gb",
          color: "RAG a 03 Blue Bicolor",
          sex: "male",
          birth: "13/5/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/lenoxxs.webp",
          mainPhoto: "assets/kittens/lenoxxl.webp",
          status: "booked",
        },
        {
          name: "Marshmallow of Annanoah*GB",
          link: "marshmallow of annanoah*gb",
          color: "RAG n 04 Seal Mitted",
          sex: "female",
          birth: "13/5/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/marshmallowxs.webp",
          mainPhoto: "assets/kittens/marshmallowxl.webp",
          status: "booked",
        },
        {
          name: "Lottie of Annanaoh*GB",
          link: "lottie of annanaoh*gb",
          color: "RAG a 03 Blue Bicolor",
          sex: "female",
          birth: "13/5/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/lottiexs.webp",
          mainPhoto: "assets/kittens/lottiexl.webp",
          status: "booked",
        },
        {
          name: "Lilianna of Annanoah*GB",
          link: "lilianna of annanoah*gb",
          color: "RAG n 03 Seal Bicolor ",
          sex: "female",
          birth: "13/5/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/liliannaxs.webp",
          mainPhoto: "assets/kittens/liliannaxl.webp",
          status: "booked",
        },
      ],
    },
    {
      litter: "M",
      father: "Ari Pragdolls",
      mother: "Gaia’s Garden Trinity*FR",
      motherPhoto: "assets/female/gaia1S.webp",
      fatherPhoto: "assets/male/ari1S.webp",
      active: false,
      kittens: [
        {
          name: "Maya of Annanoah *GB",
          link: "maya of annanoah *gb",
          color: "RAG n 03 Seal Bicolor",
          sex: "female",
          birth: "12/7/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/mayaXS.webp",
          mainPhoto: "assets/kittens/mayaXL.webp",
          status: "booked",
        },
        {
          name: "Mercii of Annanoah *GB",
          link: "mercii of annanoah *gb",
          color: "RAG n 03 Seal Bicolor",
          sex: "female",
          birth: "12/7/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/merciiXS.webp",
          mainPhoto: "assets/kittens/merciiXL.webp",
          status: "booked",
        },
        {
          name: "Rose of Annanoah*GB",
          link: "rose of annanoah*gb",
          color: "RAG n 03 Seal Bicolor",
          sex: "female",
          birth: "12/7/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/roseXS.webp",
          mainPhoto: "assets/kittens/roseXL.webp",
          status: "booked",
        },
        {
          name: "Wallace of Annanoah *GB",
          link: "wallace of annanoah *gb",
          color: "RAG n 03 Seal Bicolor",
          sex: "male",
          birth: "12/7/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/wallaceXS.webp",
          mainPhoto: "assets/kittens/wallaceXL.webp",
          status: "booked",
        },
        {
          name: "Miami of Annanoah*GB",
          link: "miami of annanoah*gb",
          color: "RAG n 03 Seal Bicolor",
          sex: "female",
          birth: "12/7/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/miamiXS.webp",
          mainPhoto: "assets/kittens/miamiXL.webp",
          status: "booked",
        },
        {
          name: "Mimi of Annanoah *GB",
          link: "mimi of annanoah *gb",
          color: "RAG n 03 Seal Bicolor",
          sex: "female",
          birth: "12/7/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/mimiXS.webp",
          mainPhoto: "assets/kittens/mimiXL.webp",
          status: "booked",
        },
      ],
    },
    {
      litter: "N",
      father: "CUKIEREK Raggato*PL",
      mother: "Hibaby GISHA",
      motherPhoto: "assets/female/HibabySmall.webp",
      fatherPhoto: "assets/male/cukierek1S.webp",
      active: false,
      kittens: [
        {
          name: "Nix of Annanoah *GB",
          link: "nix of annanoah *gb",
          color: "RAG n 03 Seal Bicolor",
          sex: "male",
          birth: "15/7/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/nixXS.webp",
          mainPhoto: "assets/kittens/nixXL.webp",
          status: "booked",
        },
        {
          name: "Nevada of Annanoah *GB",
          link: "nevada of annanoah *gb",
          color: "RAG n 03 Seal Bicolor",
          sex: "female",
          birth: "15/7/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/nevadaXS.webp",
          mainPhoto: "assets/kittens/nevadaXL.webp",
          status: "booked",
        },
        {
          name: "Nebraska of Annanoah *GB",
          link: "nebraska of annanoah *gb",
          color: "RAG a 03 Blue Bicolor",
          sex: "female",
          birth: "15/7/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/nebraskaXS.webp",
          mainPhoto: "assets/kittens/nebraskaXL.webp",
          status: "booked",
        },
        {
          name: "Neo of Annanoah *GB",
          link: "neo of annanoah *gb",
          color: "RAG n 04 Ragdoll seal Mitted",
          sex: "male",
          birth: "15/7/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/neoXS.webp",
          mainPhoto: "assets/kittens/neoXL.webp",
          status: "booked",
        },
      ],
    },
    {
      litter: "O",
      father: "CUKIEREK Raggato*PL",
      mother: "Daisy Pragdolls",
      motherPhoto: "assets/female/DaisyS.webp",
      fatherPhoto: "assets/male/cukierek1S.webp",
      active: false,
      kittens: [
        {
          name: "Ophelia of Annanoah*GB",
          link: "ophelia of annanoah*gb",
          color: "RAG n 04 21 Ragdoll Seal Mitted Lynx",
          sex: "female",
          birth: "18/8/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/opheliaXS.webp",
          mainPhoto: "assets/kittens/opheliaXL.webp",
          status: "booked",
        },
        {
          name: "Orlando of Annanoah*GB",
          link: "orlando of annanoah*gb",
          color: "RAG a 03 21 Ragdoll Blue Bicolour Lynx",
          sex: "male",
          birth: "18/8/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/orlandoXS.webp",
          mainPhoto: "assets/kittens/orlandoXL.webp",
          status: "booked",
        },
        {
          name: "Olive of Annanoah*GB",
          link: "olive of annanoah*gb",
          color: "RAG n 04 21 Ragdoll Seal Mitted Lynx",
          sex: "female",
          birth: "18/8/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/oliveXS.webp",
          mainPhoto: "assets/kittens/oliveXL.webp",
          status: "booked",
        },
        {
          name: "Olivia of Annanoah *GB",
          link: "olivia of annanoah *gb",
          color: "RAG a 04 Ragdoll Blue Mitted",
          sex: "female",
          birth: "18/8/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/oliviaXS.webp",
          mainPhoto: "assets/kittens/oliviaXL.webp",
          status: "booked",
        },
        {
          name: "Otto of Annanoah*GB",
          link: "otto of annanoah*gb",
          color: "RAG n Ragdoll Seal colorpoint ",
          sex: "male",
          birth: "18/8/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/ottoXS.webp",
          mainPhoto: "assets/kittens/ottoXL.webp",
          status: "booked",
        },
        {
          name: "Onix of Annanoah*GB",
          link: "onix of annanoah*gb",
          color: "RAG n 04 21 Ragdoll Seal Mitted Lynx",
          sex: "male",
          birth: "18/8/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/onixXS.webp",
          mainPhoto: "assets/kittens/onixXL.webp",
          status: "booked",
        },
        {
          name: "Opal of Annanoah*GB",
          link: "opal of annanoah*gb",
          color: "RAG a 04 Ragdoll Blue Mitted",
          sex: "male",
          birth: "18/8/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/opalXS.webp",
          mainPhoto: "assets/kittens/opalXL.webp",
          status: "booked",
        },
      ],
    },
    {
      litter: "P",
      father: "CUKIEREK Raggato*PL",
      mother: "HANNAH of Annanoah*GB",
      motherPhoto: "assets/female/hannah1S.webp",
      fatherPhoto: "assets/male/cukierek1S.webp",
      active: false,
      kittens: [
        {
          name: "Paris of Annanoah*GB",
          link: "paris of annanoah*gb",
          color: "RAG n 03 Ragdoll Seal Bicolour",
          sex: "female",
          birth: "17/8/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/parisXS.webp",
          mainPhoto: "assets/kittens/parisXL.webp",
          status: "booked",
        },
        {
          name: "Preston of Annanoah*GB",
          link: "preston of annanoah*gb",
          color: "RAG n 03 Ragdoll Seal Bicolour",
          sex: "male",
          birth: "17/8/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/prestonXS.webp",
          mainPhoto: "assets/kittens/prestonXL.webp",
          status: "booked",
        },
        {
          name: "Perrin of Annanoah*GB",
          link: "perrin of annanoah*gb",
          color: "RAG a 04 21 Ragdoll Blue Mitted lynx",
          sex: "male",
          birth: "17/8/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/perrinXS.webp",
          mainPhoto: "assets/kittens/perrinXL.webp",
          status: "booked",
        },
        {
          name: "Pennsylvania of Annanoah *GB",
          link: "pennsylvania of annanoah *gb",
          color: "RAG n 04 21 Ragdoll Seal Mitted Lynx",
          sex: "female",
          birth: "17/8/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/pennsylvaniaXS.webp",
          mainPhoto: "assets/kittens/pennsylvaniaXL.webp",
          status: "booked",
        },
        {
          name: "Wooden of Annanoah*GB",
          link: "wooden of annanoah*gb",
          color: "RAG n 03 Ragdoll Seal Bicolour ",
          sex: "male",
          birth: "17/8/2024",
          HCM: "",
          PKD: "",
          blood: "",
          FIV: "",
          Felv: "",
          profilePhoto: "assets/kittens/woodenXS.webp",
          mainPhoto: "assets/kittens/woodenXL.webp",
          status: "booked",
        },
      ],
    },
  ]);

  const littersActive = litter.filter((litters) => litters.active);

  const [isKitten, setIsKitten] = useState();

  useEffect(() => {
    if (littersActive.length > 0) {
      setIsKitten(true);
    } else if (littersActive.length === 0) setIsKitten(false);
  }, [littersActive]);

  useEffect(() => {
    if (isKitten) {
      document.querySelectorAll(".menu__nav-link--kittens").forEach((item) => {
        item.textContent = "! Kittens";
      });
      document
        .querySelector(".menu__nav-link--kittens--footer")
        .classList.add("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.add("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.add("menu__nav-link--yellow");
    } else if (!isKitten) {
      document.querySelectorAll(".menu__nav-link--kittens").forEach((item) => {
        item.textContent = "Kittens";
      });
      document
        .querySelector(".menu__nav-link--kittens--footer")
        .classList.remove("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.remove("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.remove("menu__nav-link--yellow");
    }
  }, [isKitten]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div>
        {<Header />}
        <section>
          <ScrollToTop>
            <Switch>
              <Route path="/" exact>
                <Home isKitten={isKitten} />
              </Route>
              <Route path="/about">
                <AboutUs isKitten={isKitten} />
              </Route>
              <Route path="/ragdoll">
                <Ragdoll isKitten={isKitten} />
              </Route>
              <Route path="/females">
                <Females isKitten={isKitten} />
              </Route>
              <Route path="/cat/:name">
                <CatInfo isKitten={isKitten} />
              </Route>
              <Route path="/image/:name">
                <Image isKitten={isKitten} />
              </Route>
              <Route path="/litters">
                <Litters isKitten={isKitten} />
              </Route>
              <Route path="/males">
                <Males isKitten={isKitten} />
              </Route>
              <Route path="/kittens">
                <Kittens state={litter} isKitten={isKitten} />
              </Route>
              <Route path="/contact">
                <Contact isKitten={isKitten} />
              </Route>
              <Route>
                <ErrorPage isKitten={isKitten} />
              </Route>
            </Switch>
          </ScrollToTop>
          <ScrollButton />
        </section>
        {<Footer />}
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B", textShadow: "2px 2px black" }}
          buttonClasses="cat__link"
          buttonStyle={{
            color: "#4e503b",
            fontSize: "14px",
          }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </div>
    </Router>
  );
}

export default App;
