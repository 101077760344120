import Nav from "./Nav";
import "../../style/header.scss";
import gsap from "gsap";
import { useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../img/logoWhiteXS.webp";

function Header() {
  const burgerContainer = useRef();
  const burgerItemOne = useRef();
  const burgerItemTwo = useRef();
  const burgerItemThree = useRef();
  const burgerItemFour = useRef();
  const burgerBcgPrimary = useRef();
  const burgerBcgSecondary = useRef();
  const burgerNav = useRef();
  const burgerBcgWrapper = useRef();
  const burgerNavWrapper = useRef();
  const burgerKitten = useRef();
  let iSMenuHidden = true;
  let windowSize = window.innerHeight;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > windowSize / 8;
      if (scrollCheck && iSMenuHidden) {
        document.querySelector(".menu").style.setProperty("height", "10vh");
        document
          .querySelector(".menu__wrapper")
          .style.setProperty("height", "10vh");
        document
          .querySelector(".menu__logo-wrapper")
          .style.setProperty("height", "10vh");
        document.querySelector(".burger").style.setProperty("height", "10vh");
        document
          .querySelector(".burger__icon-wrapper")
          .style.setProperty("height", "10vh");
        document
          .querySelector(".burger-nav__nav-wrapper")
          .style.setProperty("height", "90vh");
        document
          .querySelector(".burger-nav__nav-wrapper")
          .style.setProperty("top", "10vh");
        document
          .querySelector(".burger-nav__background-wrapper")
          .style.setProperty("height", "90vh");
        document.querySelector(".menu").style.setProperty("opacity", 0.9);
      } else if (!scrollCheck && iSMenuHidden) {
        document.querySelector(".menu").style.setProperty("height", "20vh");
        document
          .querySelector(".menu__wrapper")
          .style.setProperty("height", "20vh");
        document
          .querySelector(".menu__logo-wrapper")
          .style.setProperty("height", "20vh");
        document.querySelector(".burger").style.setProperty("height", "20vh");
        document
          .querySelector(".burger__icon-wrapper")
          .style.setProperty("height", "20vh");
        document
          .querySelector(".burger-nav__nav-wrapper")
          .style.setProperty("height", "80vh");
        document
          .querySelector(".burger-nav__nav-wrapper")
          .style.setProperty("top", "20vh");
        document
          .querySelector(".burger-nav__background-wrapper")
          .style.setProperty("height", "80vh");
        document.querySelector(".menu").style.setProperty("opacity", 1);
      }
    });
  });

  const t1 = gsap.timeline();
  const timelinePlay = () => {
    t1.pause()
      .set(burgerItemThree.current, { x: "0%", y: "200%" })
      .set(burgerItemFour.current, { x: "0%", y: "-300%" })
      .addLabel("start")
      .to(
        burgerItemThree.current,
        { x: "0%", y: "-50%", duration: 0.6 },
        "start"
      )
      .to(
        burgerItemFour.current,
        { x: "0%", y: "-50%", duration: 0.6 },
        "start"
      )
      .set(burgerItemThree.current, { opacity: "0" })
      .set(burgerItemFour.current, { opacity: "0" })
      .addLabel("x")
      .to(burgerItemOne.current, { rotate: "45deg", duration: 0.6 }, "x")
      .to(burgerItemTwo.current, { rotate: "-45deg", duration: 0.6 }, "x");
  };

  const t2 = gsap.timeline();
  const timeline2Play = () => {
    t2.pause()
      .addLabel("start")
      .fromTo(
        burgerBcgPrimary.current,
        { y: "-100%", autoAlpha: 0 },
        { y: "0%", autoAlpha: 1, delay: 0.01, duration: 0.5 },
        "start"
      )
      .fromTo(
        burgerBcgSecondary.current,
        { y: "-100%", autoAlpha: 0 },
        { y: "0%", autoAlpha: 1, duration: 0.5 },
        "start"
      )
      .fromTo(
        burgerNav.current,
        { autoAlpha: 0, zIndex: 0 },
        { autoAlpha: 1, zIndex: 50, delay: 0.5 },
        "start"
      )
      .fromTo(
        burgerNavWrapper.current,
        { zIndex: 0, autoAlpha: 0 },
        { autoAlpha: 1, zIndex: 50 },
        "start"
      )
      .fromTo(
        burgerBcgWrapper.current,
        { autoAlpha: 0, zIndex: 0 },
        { autoAlpha: 1, zIndex: 50 },
        "start"
      )
      .to(document.querySelector(".menu"), {
        autoAlpha: 1,
      });
  };

  useEffect(() => {
    timeline2Play();
    timelinePlay();
  });

  const menuHide = () => {
    burgerContainer.current.classList.toggle("active");
    iSMenuHidden = !iSMenuHidden;
    t1.reverse();
    t2.reverse();
  };

  const burgerSwitchHandler = () => {
    if (!burgerContainer.current.classList.contains("active")) {
      burgerContainer.current.classList.toggle("active");
      iSMenuHidden = false;
      t1.play();
      t2.play();
    } else {
      burgerContainer.current.classList.toggle("active");
      iSMenuHidden = true;
      t1.reverse();
      t2.reverse();
    }
  };

  return (
    <header>
      <div className="menu">
        <div className="menu__wrapper">
          <div className="menu__logo-wrapper">
            <img
              className="menu__logo"
              src={logo} //"assets/logo/logoWhiteXS.webp"
              alt="annanoah cattery logo"
            />
          </div>
          <nav className="menu__nav">
            <div className="menu__nav-wrapper">{<Nav />}</div>
          </nav>
          <div className="burger">
            <div className="burger__icon-wrapper">
              <div
                className="burger__container"
                ref={burgerContainer}
                onClick={burgerSwitchHandler}
              >
                <div className="burger__item1" ref={burgerItemOne}></div>
                <div className="burger__item2" ref={burgerItemTwo}></div>
                <div className="burger__item3" ref={burgerItemThree}></div>
                <div className="burger__item4" ref={burgerItemFour}></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="burger-nav__nav-wrapper" ref={burgerNavWrapper}>
        <div className="burger-nav__background-wrapper" ref={burgerBcgWrapper}>
          <div
            className="burger-nav__background-primary"
            ref={burgerBcgPrimary}
          ></div>
          <div
            className="burger-nav__background-secondary"
            ref={burgerBcgSecondary}
          ></div>
          <ul className="burger-nav__nav-content" ref={burgerNav}>
            <li className="burger-nav__nav-item">
              <NavLink
                to="/"
                exact
                className="burger-nav__nav-link"
                activeClassName="home_active"
                onClick={menuHide}
              >
                Home
              </NavLink>
            </li>
            <li className="burger-nav__nav-item">
              <NavLink
                to="/about"
                className="burger-nav__nav-link"
                activeClassName="about_active"
                onClick={menuHide}
              >
                About Us
              </NavLink>
            </li>
            <li className="burger-nav__nav-item">
              <NavLink
                to="/ragdoll"
                className="burger-nav__nav-link"
                activeClassName="ragdoll_active"
                onClick={menuHide}
              >
                Ragdoll
              </NavLink>
            </li>
            <li className="burger-nav__nav-item">
              <NavLink
                to="/females"
                className="burger-nav__nav-link"
                activeClassName="females_active"
                onClick={menuHide}
              >
                Females
              </NavLink>
            </li>
            <li className="burger-nav__nav-item">
              <NavLink
                to="/males"
                className="burger-nav__nav-link"
                activeClassName="males_active"
                onClick={menuHide}
              >
                Males
              </NavLink>
            </li>
            <li className="burger-nav__nav-item">
              <NavLink
                to="/kittens"
                className="burger-nav__nav-link menu__nav-link--kittens menu__nav-link--kittens--burger"
                ref={burgerKitten}
                activeClassName="kittens_active"
                onClick={menuHide}
              >
                Kittens
              </NavLink>
            </li>
            <li className="burger-nav__nav-item">
              <NavLink
                to="/contact"
                className="burger-nav__nav-link"
                activeClassName="contact_active"
                onClick={menuHide}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
