import "../style/catInfo.scss";
import "../style/kittens.scss";
import "../style/litters.scss";
import { useHistory, Link } from "react-router-dom";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function Litters(props) {
  const history = useHistory();
  useEffect(() => {
    if (props.isKitten) {
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.add("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.add("menu__nav-link--yellow");
    } else if (!props.isKitten) {
      document.querySelectorAll(".menu__nav-link--kittens").forEach((item) => {
        item.textContent = "Kittens";
      });
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.remove("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.remove("menu__nav-link--yellow");
    }
  }, [props.isKitten]);
  const litters = [...history.location.state.litter]
    .filter((litters) => !litters.active)
    .map((litter) => {
      const kittens = litter.kittens.map((kitten) => {
        return (
          <li className="cat__item-litter" key={kitten.name} content={litter}>
            <h3 className="cat__title-litter">{kitten.name}</h3>
            <div className="cat__info-litter">
              <p className="kittens__text kittens__text-data">
                Date of Birth:{" "}
                <span className="kittens__text kittens__text-span">
                  {kitten.birth}
                </span>
              </p>
              <p className="kittens__text kittens__text-data">
                Color:{" "}
                <span className="kittens__text kittens__text-span">
                  {kitten.color}
                </span>
              </p>
              <p className="kittens__text kittens__text-data">
                Sex:{" "}
                <span className="kittens__text kittens__text-span">
                  {kitten.sex}
                </span>
              </p>
            </div>
            <div className="cat__img-wrapper-litter">
              <Link
                className="cat__img-wrapper-litter--center"
                target="_self"
                to={{
                  pathname: `/image/${kitten.link}`,
                  state: {
                    name: kitten.name,
                    profilePhoto: kitten.profilePhoto,
                    mainPhoto: kitten.mainPhoto,
                  },
                }}
              >
                <img
                  className="litters__img--link"
                  src={`${kitten.profilePhoto}`}
                  alt={`${kitten.name}`}
                />
              </Link>
            </div>
          </li>
        );
      });
      return (
        <li
          className="litter__item-litter"
          key={litter.litter}
          content={litter}
        >
          <h2 className="kittens__text kittens__text--title">
            Litter:
            <p className="kittens__text--big kittens__text--color">
              {litter.litter}
            </p>
          </h2>
          <div className="litters__parrents-wrapper">
            <h3 className="kittens__text kittens__important litters__parents">
              Parents:
            </h3>
            <div className="litters__mother-wrapper">
              <p className="kittens__text litters__mother">{litter.mother}</p>
              <img
                className="litters__img litters__mother-img"
                src={litter.motherPhoto}
                alt={litter.mother}
              />
            </div>
            <div className="litters__father-wrapper">
              <p className="kittens__text litters__father">{litter.father}</p>
              <img
                className="litters__img litters__father-img"
                src={litter.fatherPhoto}
                alt={litter.father}
              />
            </div>
          </div>
          <ul className="litters__wrapper">{kittens}</ul>
        </li>
      );
    });

  return (
    <>
      <Helmet>
        <title>
          Litters | Annanoah Ragdoll Cattery GB - Oxfordshire United Kingdom
        </title>
        <link rel="canonical" href="https://annanoahcattery.com/litters" />
      </Helmet>
      <main className="litters">
        <h1 className="litters__title">Our Litters</h1>
        <div className="litters__wrapper">
          <ul className="litters__wrapper">{litters}</ul>
        </div>
        <div className="litters__button-container">
          <button onClick={() => history.goBack()} className="cat-info__button">
            Back
          </button>
        </div>
      </main>
    </>
  );
}

export default Litters;
