import "../../style/footer.scss";
import { Route, Switch, NavLink, Link } from "react-router-dom";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faEnvelope,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import PhoneNumber from "react-phone-number";
import catsFooter from "../../img/alfaSmall.webp";
import catsFooter1px from "../../img/footerCats1px.webp";
import logoColorS from "../../img/logoColorS.webp";
import logoColorXS from "../../img/logoColorXS.webp";
import logoColorM from "../../img/logoColorM.webp";
import logoColorXXS from "../../img/logoColorXXS.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__borderTop"></div>
      <div className="footer__main-wrapper">
        <div className="footer__left">
          <div className="footer__logo-wrapper">
            <picture className="footer__picture">
              <source media="(min-width: 1024px)" srcSet={logoColorS} />
              <source media="(min-width: 650px)" srcSet={logoColorXS} />
              <source media="(min-width: 400px)" srcSet={logoColorM} />
              <img
                className="footer__logo"
                src={logoColorXXS}
                alt="annanoah catery logo"
              />
            </picture>
          </div>
        </div>

        <div className="footer__center">
          <p className="footer__text">Contact data:</p>
          <div className="footer__data-container">
            <div className="footer__contact-data">
              <FontAwesomeIcon
                className="footer__center-icon"
                icon={faMapMarker}
              />
              <p className="footer__text-data">Brize Norton, United Kingdom</p>
            </div>

            <div className="footer__contact-data">
              <FontAwesomeIcon className="footer__center-icon" icon={faPhone} />
              <p className="footer__text-data">
                <PhoneNumber
                  number="+4407395660201"
                  target="_blank"
                  isLinked={true}
                  rel="noopener noreferrer nofollow"
                >
                  +44 07395660201
                </PhoneNumber>
              </p>
            </div>

            <div className="footer__contact-data">
              <FontAwesomeIcon
                className="footer__center-icon"
                icon={faEnvelope}
              />
              <p className="footer__text-data">
                <Link
                  to={{ pathname: "mailto:annanoah.gb@mail.com" }}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  annanoah.gb@mail.com
                </Link>
              </p>
            </div>

            <div className="footer__contact-data">
              <FontAwesomeIcon
                className="footer__center-icon"
                icon={faFacebookF}
              />
              <p className="footer__text-data">
                <Link
                  to={{
                    pathname:
                      "https://www.facebook.com/Annanoah-GB-Ragdoll-Cattery-102075491924910",
                  }}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Annanoah GB - Ragdoll Cattery
                </Link>
              </p>
            </div>

            <div className="footer__contact-data">
              <FontAwesomeIcon
                className="footer__center-icon"
                icon={faInstagram}
              />
              <p className="footer__text-data">
                <Link
                  to={{
                    pathname:
                      "https://www.instagram.com/annanoah_ragdoll_cattery/",
                  }}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  annanoah_ragdoll_cattery
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className="footer__right">
          <div className="footer__img-wrapper">
            <picture className="footer__picture">
              <source media="(min-width: 1024px)" srcSet={catsFooter} />
              <img className="footer__img" src={catsFooter1px} alt="two cats" />
            </picture>
          </div>
        </div>

        <div className="footer__bottom">
          <div className="footer__bottom-left">
            <p className="footer__text">Site Map:</p>
            <nav className="footer__links">
              <NavLink to="/" exact activeClassName="footer__active">
                <p className="footer__link">Home</p>
              </NavLink>
              <p className="footer__links-before">|</p>
              <NavLink to="/about" activeClassName="footer__active">
                <p className="footer__link">About Us</p>
              </NavLink>
              <p className="footer__links-before">|</p>
              <NavLink to="/ragdoll" activeClassName="footer__active">
                <p className="footer__link">Ragdoll</p>
              </NavLink>
              <p className="footer__links-before">|</p>
              <NavLink to="/females" activeClassName="footer__active">
                <p className="footer__link">Females</p>
              </NavLink>
              <p className="footer__links-before">|</p>
              <NavLink to="/males" activeClassName="footer__active">
                <p className="footer__link">Males</p>
              </NavLink>
              <p className="footer__links-before">|</p>
              <NavLink to="/kittens" activeClassName="footer__active">
                <p className="footer__link menu__nav-link--kittens menu__nav-link--kittens--footer">
                  Kittens
                </p>
              </NavLink>
              <p className="footer__links-before">|</p>
              <NavLink to="/contact" activeClassName="footer__active">
                <p className="footer__link">Contact</p>
              </NavLink>
            </nav>
          </div>
          <div className="footer__bottom-right">
            <p className="footer__company-name">
              <Link
                to={{
                  pathname: "https://kidev.pl/",
                }}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Krzysztof Gąsiorowski © 2021
              </Link>
            </p>
          </div>
        </div>

        <div className="footer__route">
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => {
                return (
                  <>
                    <p>
                      You are on <span>home</span> page
                    </p>
                  </>
                );
              }}
            />
            <Route
              path="/:page"
              exact
              render={(props) => {
                return (
                  <>
                    <p>
                      You are on <span>{props.match.params.page}</span> page
                    </p>
                  </>
                );
              }}
            />
          </Switch>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
