import "../style/catInfo.scss";
import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function Image(props) {
  const history = useHistory();
  useEffect(() => {
    if (props.isKitten) {
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.add("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.add("menu__nav-link--yellow");
    } else if (!props.isKitten) {
      document.querySelectorAll(".menu__nav-link--kittens").forEach((item) => {
        item.textContent = "Kittens";
      });
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.remove("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.remove("menu__nav-link--yellow");
    }
  }, [props.isKitten]);
  return (
    <>
      <Helmet>
        <title>
          Cat Image | Annanoah Ragdoll Cattery GB - Oxfordshire United Kingdom
        </title>
      </Helmet>
      <main className="cat-info">
        <h1 className="cat-info__title">{history.location.state.name}</h1>
        <div className="cat-info__img-container cat-info__img-container--big">
          <img
            className="cat-info__img cat-info__img-main cat-info__img--big"
            src={history.location.state.mainPhoto}
            alt={history.location.state.name}
          />
        </div>
        <div className="cat-info__button-container">
          <button onClick={() => history.goBack()} className="cat-info__button">
            Back
          </button>
        </div>
      </main>
    </>
  );
}

export default Image;
