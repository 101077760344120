import { NavLink } from "react-router-dom";
import { useRef } from "react";

function Nav() {
  const burgerNav = useRef();
  return (
    <ul className="menu__nav-content" ref={burgerNav}>
      <li className="menu__nav-item">
        <NavLink
          to="/"
          exact
          className="menu__nav-link"
          activeClassName="home_active"
        >
          Home
        </NavLink>
      </li>
      <li className="menu__nav-item">
        <NavLink
          to="/about"
          className="menu__nav-link"
          activeClassName="about_active"
        >
          About Us
        </NavLink>
      </li>
      <li className="menu__nav-item">
        <NavLink
          to="/ragdoll"
          className="menu__nav-link"
          activeClassName="ragdoll_active"
        >
          Ragdoll
        </NavLink>
      </li>
      <li className="menu__nav-item">
        <NavLink
          to="/females"
          className="menu__nav-link"
          activeClassName="females_active"
        >
          Females
        </NavLink>
      </li>
      <li className="menu__nav-item">
        <NavLink
          to="/males"
          className="menu__nav-link"
          activeClassName="males_active"
        >
          Males
        </NavLink>
      </li>
      <li className="menu__nav-item">
        <NavLink
          to="/kittens"
          className="menu__nav-link menu__nav-link--kittens menu__nav-link--kittens--header"
          activeClassName="kittens_active"
        >
          Kittens
        </NavLink>
      </li>
      <li className="menu__nav-item">
        <NavLink
          to="/contact"
          className="menu__nav-link"
          activeClassName="contact_active"
        >
          Contact
        </NavLink>
      </li>
    </ul>
  );
}

export default Nav;
