import "../style/error.scss";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import errorLarge from "../img/errorM.webp";
import errorMedium from "../img/errorS.webp";
import errorSmall from "../img/errorXS.webp";

function ErrorPage(props) {
  useEffect(() => {
    if (props.isKitten) {
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.add("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.add("menu__nav-link--yellow");
    } else if (!props.isKitten) {
      document.querySelectorAll(".menu__nav-link--kittens").forEach((item) => {
        item.textContent = "Kittens";
      });
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.remove("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.remove("menu__nav-link--yellow");
    }
  }, [props.isKitten]);
  return (
    <>
      <Helmet>
        <title>
          Error Page | Annanoah Ragdoll Cattery GB - Oxfordshire United Kingdom
        </title>
      </Helmet>
      <main className="error">
        <h1 className="error__title">Błąd 404</h1>
        <p className="error__text">Strona o podanym adresie nie istnieje</p>
        <div className="error__img-container">
          <picture className="error__picture">
            <source media="(min-width: 800px)" srcSet={errorLarge} />
            <source media="(min-width: 300px)" srcSet={errorMedium} />
            <img
              className="error__img"
              src={errorSmall}
              alt="ragdoll kitten is looking up"
            />
          </picture>
        </div>
      </main>
    </>
  );
}

export default ErrorPage;
