import "../style/ragdoll.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../style/carousel.scss";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import React, { lazy, Suspense } from "react";
import ragdoll6L from "../img/ragdoll6L.webp";
import ragdoll6M from "../img/ragdoll6M.webp";
import ragdoll6S from "../img/ragdoll6S.webp";
import ragdoll6XS from "../img/ragdoll6XS.webp";
import ragdoll6XXS from "../img/ragdoll6XXS.webp";
const Carousel = lazy(() => import("./CarouselMy"));

function Ragdoll(props) {
  useEffect(() => {
    if (props.isKitten) {
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.add("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.add("menu__nav-link--yellow");
    } else if (!props.isKitten) {
      document.querySelectorAll(".menu__nav-link--kittens").forEach((item) => {
        item.textContent = "Kittens";
      });
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.remove("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.remove("menu__nav-link--yellow");
    }
  }, [props.isKitten]);
  return (
    <>
      <Helmet>
        <title>
          Ragdoll Cats | Annanoah Ragdoll Cattery GB - Oxfordshire United
          Kingdom
        </title>
        <link rel="canonical" href="https://annanoahcattery.com/ragdoll" />
      </Helmet>
      <main className="ragdoll">
        <h1 className="ragdoll__title">Ragdoll cats</h1>
        <article className="ragdoll__history ragdoll__section">
          <h2 className="ragdoll__text ragdoll__important ragdoll__important--title">
            Ragdoll history
          </h2>
          <p className="ragdoll__text">
            The Ragdoll's history is brimming with myths and half-truths, but it
            is known that they were first bred and developed in the 1960’s by
            Ann Baker a breeder in Riverside California. Baker bred a white
            Angora Persian type cat queen, Josephine, it’s believed they are the
            result of a mating between a female white Persian and a male Birman
            cat. Josephine’s kittens seemed to have a true loving nature and
            beautiful blue eyes also extremely calm. Baker soon knew these
            kittens were very special. She kept some of them and bred them very
            carefully.A few years later they were officially registered with the
            National Cat Fanciers Association and the Ragdoll breed began…
          </p>
        </article>
        <article className="ragdoll__temperament ragdoll__section">
          <h2 className="ragdoll__text ragdoll__important ragdoll__important--title">
            Ragdoll Temperament
          </h2>
          <p className="ragdoll__text">
            Ragdolls are calm and friendly ,loving and cheerful their super-soft
            fluffy coats and blue eyes mean that Ragdolls often resemble cuddly
            little teddy bears. They love human company and are very trusting –
            their name comes from their tendency to relax when held, making them
            great companions. Ragdolls have an almost dog-like devotion to their
            owners. Some people also say they are attuned to emotional needs, a
            characteristic that makes them a perfect companion animal.
          </p>
          <div className="ragdoll__cat-img-container">
            <picture className="about-us__picture">
              <source media="(min-width: 800px)" srcSet={ragdoll6L} />
              <source media="(min-width: 600px)" srcSet={ragdoll6M} />
              <source media="(min-width: 480px)" srcSet={ragdoll6S} />
              <source media="(min-width: 300px)" srcSet={ragdoll6XS} />
              <source media="(min-width: 200px)" srcSet={ragdoll6XXS} />
              <img
                className="ragdoll__img"
                src={ragdoll6XXS}
                alt="Two Ragdoll cats are lying on the table"
              />
            </picture>
          </div>
        </article>
        <article className="ragdoll__characteristic ragdoll__section">
          <h2 className="ragdoll__text ragdoll__important ragdoll__important--title">
            Ragdoll Cats Characteristic
          </h2>
          <p className="ragdoll__text">
            The Ragdoll is a semi longhair cat and one of the largest
            domesticated cat breeds. The weight of a male is from 4.5 to 10 kg
            (12 to 20 pounds) and females up to 6kg (up to 15 pounds) with a
            large appearance. Their coat is thick, soft and silky called a plush
            coat. Ragdoll cats heads are broad with a flat top and wide space
            between the medium size ears. They have long, muscular bodies with
            broad chests and short necks. Nose is slightly bent at the top with
            a well sized chin. Eyes are large round and deep blue. Ragdoll cats
            tails are bushy and long in length, paws are large, round, and
            tufted with hair amongst the toes.
          </p>
        </article>
        <article className="ragdoll__colors ragdoll__section">
          <h2 className="ragdoll__text ragdoll__important ragdoll__important--title">
            The ragdoll can be found in 6 different colours and 3 different
            markings:
          </h2>
          <p className="ragdoll__text ragdoll__important">Colours:</p>
          <ul className="ragdoll__list">
            <li className="ragdoll__list-item">
              <b>Seal</b> - dark brown, butine black points
            </li>
            <li className="ragdoll__list-item">
              <b>Blue</b> - Blue, steel blue, foot leathers are grey
            </li>
            <li className="ragdoll__list-item">
              <b>Chocolate</b> - chocolate colour and lighter than the seal
            </li>
            <li className="ragdoll__list-item">
              <b>Lilac</b> - very light blue
            </li>
            <li className="ragdoll__list-item">
              <b>Red</b> - Red, red points
            </li>
            <li className="ragdoll__list-item">
              <b>Cream</b> - dilution of red, is lighter in colour
            </li>
          </ul>
          <p className="ragdoll__text ragdoll__important">
            There are also 3 patterns that ragdoll can have such as:
          </p>
          <ul className="ragdoll__list">
            <li className="ragdoll__list-item">
              <b>Tabby</b> (lynx)
            </li>
            <li className="ragdoll__list-item">
              <b>Tortie</b>
            </li>
            <li className="ragdoll__list-item">
              <b>Torbie</b>
            </li>
          </ul>
          <p className="ragdoll__text ragdoll__important">Markings:</p>
          <ul className="ragdoll__list">
            <li className="ragdoll__list-item">
              <b>Colourpoint</b> - Points that is: mask, ears, paws and tail are
              coloured. Body colour is lighter, namely cream-colored. The chest
              is usually lighter than the rest of the body. There are
              Colourpoints with and without abdominal spot. A belly spot is
              allowed, but not desired. A Colourpoint should have absolutely no
              white.
            </li>
            <li className="ragdoll__list-item">
              <b>Mitted</b> - Mitted means gloved. With a mitted, the points are
              coloured. But they clearly distinguish themselves from the
              Colourpoint; the front legs have white gloves and the hind legs
              white boots. Chin and chest are white. From the chin to the tail
              implant runs a white stripe, the so-called belly stripe. Some
              Mittens can have a white blaze on their nose. Body colour is cream
              coloured.
            </li>
            <li className="ragdoll__list-item">
              <b>Bicolour</b> - has an inverted white V on the head, from the
              forehead line runs across the cheeks within the outer corners of
              the eye and passes into the white of the collar. The front legs
              are white up to the elbows and the hind legs up to the line from
              the tail start to the knee. The back is a shade lighter from the
              points (tail and ears) and may contain white spots. All-white
              front legs are preferable. The nose leather and the soles of the
              feet are pink.
            </li>
          </ul>
        </article>
        <article className="ragdoll__gallery ragdoll__section">
          <h2 className="ragdoll__text ragdoll__important ragdoll__important--title">
            Ragdoll Cats Gallery
          </h2>
          <Suspense fallback={<></>}>
            <Carousel />
          </Suspense>
        </article>
      </main>
    </>
  );
}

export default Ragdoll;
