import React, { useEffect, useState } from "react";
import "../style/females.scss";
import "../style/cat.scss";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

function Females(props) {
  const [females] = useState([
    /* {
      name: "Lopapejsa Beesha*PL",
      link: "lopapejsa beesha*pl",
      description:
        "Lopapejsa - I call her Pepa , from the very first day she arrived from Poland she is very confident and wanted to be loved. She never gets tired of cuddles! Lopapejsa is coming from great champion lines and is a big solid female! Plush coat , beautiful round eyes , small ears and perfectly marked this is Lopapejsa! A special thank you to Marika Molecka Beesha* PL the breeder. We built a strong relationship and huge trust and that was the key to letting this happen !",
      color: "RAG a 04 Ragdoll Blue Mitted",
      birth: "20/11/2020",
      father: "IC Rasul Sali Cat*PL",
      mother: "Blaire Nitoris*PL",
      HCM: "N/N (-)",
      PKD: "N/N (-)",
      blood: "gr.A",
      FIV: "(-)",
      Felv: "(-)",
      profilePhoto: "assets/female/LopapejsaSmall.webp",
      mainPhoto: "assets/female/LopapejsaM.webp",
      photo1: "assets/female/Lopapejsa1XS.webp",
      photo2: "assets/female/Lopapejsa2XS.webp",
      photo3: "assets/female/Lopapejsa5XS.webp",
      photo4: "assets/female/Lopapejsa4XS.webp",
    }, */
    /* {
      name: "Loombera Beesha*PL",
      link: "loombera beesha*pl",
      description:
        "Loombera is the sister of Lopapejsa from the same litter. A blue point with great champion lines that l am hoping we can continue to achieve  in the near future! Loombera is a big solid female with a good profile and strong chin. This together with her calm and delicate temperament l have found a perfect Ragdoll! Loombera loves to play and fetch, she is always bringing her toys back to me !",
      color: "RAG a  Ragdoll Blue Point",
      birth: "20/11/2020",
      father: "IC Rasul Sali Cat*PL",
      mother: "Blaire Nitoris*PL",
      HCM: "N/N (-)",
      PKD: "N/N (-)",
      blood: "gr.A",
      FIV: "(-)",
      Felv: "(-)",
      profilePhoto: "assets/female/LoomberaSmall.webp",
      mainPhoto: "assets/female/LoomberaMedium.webp",
      photo1: "assets/female/Loombera1XSmall.webp",
      photo2: "assets/female/Loombera2XSmall.webp",
      photo3: "assets/female/Loombera3XSmall.webp",
      photo4: "assets/female/Loombera4XSmall.webp",
    }, */
    /* {
      name: "Hibaby GISHA",
      link: "hibaby gisha",
      description:
        "First bicolour in my cattery , Gisha is a dream come true ! Thank you to Basia for your trust and for allowing me to have Gisha. Absolutely amazing representative of Ragdoll from, Head to toe “ very big and heavy with a beautiful tail! Gisha is very sweet and she loves attention all day long, l absolutely love her character and the way she is acting, Madame Gisha. She is also the best friend of my one year old son!",
      color: "RAG n 03  Seal point bicolor",
      birth: "02/02/2021",
      father: "Adenragdoll Romeo of Hibaby",
      mother: "Hibaby Olla",
      HCM: "N/N (-)",
      PKD: "N/N (-)",
      blood: "gr.A",
      FIV: "(-)",
      Felv: "(-)",
      profilePhoto: "assets/female/HibabySmall.webp",
      mainPhoto: "assets/female/HibabyMedium.webp",
      photo1: "assets/female/Hibaby1XSmall.webp",
      photo2: "assets/female/Hibaby2XSmall.webp",
      photo3: "assets/female/Hibaby5XS.webp",
      photo4: "assets/female/Hibaby6XS.webp",
    }, */
    {
      name: "Daisy Pragdolls*CZ",
      link: "daisy",
      description:
        "Daisy - blue mitted lynx with beautiful dark blue eyes, it was love at first site. She has a very soft coat and great confidence together with a loving character. I would describe Daisy as perfect and that is extremely important in my breeding program. Also the relationship l have built with her breeder - Milada Kratka, she’s experienced, amazing and an honest breeder that l have huge respect for! Thank you once again for everything you do Milada you are amazing!",
      color: "RAG a 04 21 Ragdoll Blue Mitted Lynx",
      birth: "09/08/2021",
      father: "Arcattarags Tiger of Pragdolls",
      mother: "S*Gunbertus Modesty Blaise",
      HCM: "N/N (-)",
      PKD: "N/N (-)",
      blood: "gr.A",
      FIV: "(-)",
      Felv: "(-)",
      profilePhoto: "assets/female/Daisy4aS.webp",
      mainPhoto: "assets/female/Daisy4aM.webp",
      photo1: "assets/female/Daisy1aXS.webp",
      photo2: "assets/female/Daisy2aXS.webp",
      photo3: "assets/female/Daisy3aXS.webp",
      photo4: "assets/female/Daisy5aXS.webp",
    },
    /* {
      name: "Dakota of Annanoah*GB",
      link: "dakota",
      description:
        "Dakota our keeper girl, daughter of Gisha and Fabio. She is a sweet girl , very loving and very big .Dakota is just 8 months old but is the heaviest and biggest overall in my breeding program , weight at this moment 6.8 kg - unbelievable as she is still just a kitten. We are hoping for kittens in 2023 from Dakota and Ari - fingers crossed.",
      color: "RAG a 03  Blue Bicolor",
      birth: "09/06/2021",
      father: "Fabio Hold Me*PL",
      mother: "GISHA Hibaby*PL",
      HCM: "N/N (-)",
      PKD: "N/N (-)",
      blood: "gr.A",
      FIV: "(-)",
      Felv: "(-)",
      profilePhoto: "assets/female/DakotaS.webp",
      mainPhoto: "assets/female/DakotaM.webp",
      photo1: "assets/female/Dakota1XS.webp",
      photo2: "assets/female/Dakota2XS.webp",
      photo3: "assets/female/Dakota3XS.webp",
      photo4: "assets/female/Dakota4XS.webp",
    }, */
    /* {
      name: "Gaia’s Garden Trinity*FR",
      link: "gaias garden",
      description:
        "Trinity came to us from my favourite place in Europe- Paris , France. I would like to thank Anais Heraud for this absolutely amazing girl! Trinity is everything l was looking for to add to my breeding program. She is absolutely gorgeous, great pedigree, big girl with such a sweet face but this isn’t everything that l pay attention too, l have to say that her character is a dream…extremely friendly and loving!!! I am very happy and from this place and opportunity l thank you for the trust and letting me to have her. I promise she will enjoy her new home in United Kingdom. Merci beaucoup! ",
      color: "RAG n 03 Ragdoll Seal Bicolor",
      birth: "18/10/2022",
      father: "Malatodolls Lego*ES",
      mother: "Ginger Ancymony*PL",
      HCM: "N/N (-)",
      PKD: "N/N (-)",
      blood: "gr.A",
      FIV: "(-)",
      Felv: "(-)",
      profilePhoto: "assets/female/gaia1S.webp",
      mainPhoto: "assets/female/gaia1M.webp",
      photo1: "assets/female/gaia1XS.webp",
      photo2: "assets/female/gaia2XS.webp",
      photo3: "assets/female/gaia3XS.webp",
      photo4: "assets/female/gaia4XS.webp",
    }, */
    /* {
      name: "TOSCANA Beesha*PL",
      link: "toscana beesha",
      description: "",
      color: "RAG a 03 Ragdoll Blue Bicolour",
      birth: "23/09/2023",
      father: "GEORGE BRIGHT CRYSTAL*CZ",
      mother: "PHILADELPHIA LUCKA-ORNEL*CZ",
      HCM: "N/N (-)",
      PKD: "N/N (-)",
      blood: "gr.A",
      FIV: "(-)",
      Felv: "(-)",
      profilePhoto: "assets/female/Toscana1S.webp",
      mainPhoto: "assets/female/Toscana1M.webp",
      photo1: "assets/female/Toscana5XS.webp",
      photo2: "assets/female/Toscana2XS.webp",
      photo3: "assets/female/Toscana3XS.webp",
      photo4: "assets/female/Toscana4XS.webp",
    }, */
    {
      name: "HANNAH of Annanoah*GB",
      link: "hannah of annanoah",
      description: "",
      color: "RAG a 03 21 Ragdoll Blue Bicolour Lynx",
      birth: "27/05/2023",
      father: "ARI PRAGDOLLS*CZ",
      mother: "DAISY PRAGDOLLS*CZ",
      HCM: "N/N (-)",
      PKD: "N/N (-)",
      blood: "gr.A",
      FIV: "(-)",
      Felv: "(-)",
      profilePhoto: "assets/female/hannah1S.webp",
      mainPhoto: "assets/female/hannah1M.webp",
      photo1: "assets/female/hannah4xs.webp",
      photo2: "assets/female/hannah2xs.webp",
      photo3: "assets/female/hannah1xs.webp",
      photo4: "assets/female/hannah3xs.webp",
    },
    {
      name: "Jasmine of Annanoah*GB",
      link: "jasmine of annanoah",
      description: "",
      color: "RAG n 03 Ragdoll Seal Bicolour",
      birth: "23/11/2023",
      father: "ARI PRAGDOLLS*CZ",
      mother: "GAIA’S GARDEN TRINITY*FR",
      HCM: "N/N (-)",
      PKD: "N/N (-)",
      blood: "gr.A",
      FIV: "(-)",
      Felv: "(-)",
      profilePhoto: "assets/female/jasmine1S.webp",
      mainPhoto: "assets/female/jasmine1M.webp",
      photo1: "assets/female/jasmine4XS.webp",
      photo2: "assets/female/jasmine2XS.webp",
      photo3: "assets/female/jasmine1XS.webp",
      photo4: "assets/female/jasmine3XS.webp",
    },
  ]);

  const history = useHistory();

  function handleClick() {
    history.push();
  }
  useEffect(() => {
    if (props.isKitten) {
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.add("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.add("menu__nav-link--yellow");
    } else if (!props.isKitten) {
      document.querySelectorAll(".menu__nav-link--kittens").forEach((item) => {
        item.textContent = "Kittens";
      });
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.remove("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.remove("menu__nav-link--yellow");
    }
  }, [props.isKitten]);

  const femalesList = females.map((cat) => (
    <li className="cat__item" key={cat.name} content={cat}>
      <h3 className="cat__title">{cat.name}</h3>
      <div className="cat__img-wrapper">
        <img
          className="cat__img"
          src={`${cat.profilePhoto}`}
          alt={`${cat.name}`}
        />
      </div>
      <Link
        onClick={handleClick}
        className="cat__link"
        to={{
          pathname: `/cat/${cat.link}`,
          state: {
            name: cat.name,
            description: cat.description,
            color: cat.color,
            birth: cat.birth,
            father: cat.father,
            mother: cat.mother,
            HCM: cat.HCM,
            PKD: cat.PKD,
            blood: cat.blood,
            FIV: cat.FIV,
            Felv: cat.Felv,
            profilePhoto: cat.profilePhoto,
            mainPhoto: cat.mainPhoto,
            photo1: cat.photo1,
            photo2: cat.photo2,
            photo3: cat.photo3,
            photo4: cat.photo4,
          },
        }}
      >
        More info
      </Link>
    </li>
  ));

  return (
    <>
      <Helmet>
        <title>
          Females | Annanoah Ragdoll Cattery GB - Oxfordshire United Kingdom
        </title>
        <link rel="canonical" href="https://annanoahcattery.com/females" />
      </Helmet>
      <main className="females">
        <h1 className="females__title">Females</h1>
        <div className="females__wrapper">
          <ul className="females__list">{femalesList}</ul>
        </div>
      </main>
    </>
  );
}

export default Females;
