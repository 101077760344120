import React, { useEffect, useState, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import "../style/contact.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faEnvelope,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import PhoneNumber from "react-phone-number";
import { Helmet } from "react-helmet";
import contact3M from "../img/contact3M.webp";
import contact3S from "../img/contact3S.webp";
import contact3XS from "../img/contact3XS.webp";
import contact4M from "../img/contact4M.webp";
import contact4S from "../img/contact4S.webp";
import contact41px from "../img/footerCats1px.webp";
import { send, init } from "emailjs-com";
const ReCAPTCHA = lazy(() => import("react-google-recaptcha"));
//import ReCAPTCHA from "react-google-recaptcha";
const { REACT_APP_RECAPTCHA_KEY } = process.env;

function Contact(props) {
  const [status, setStatus] = useState(undefined);
  init("user_7NRY5wZwosMXO7UZDrT7e");

  function onChange(value) {
    console.log("Captcha value:", value);
    document.querySelector(".contact__btn").disabled = false;
  }

  function onExpired() {
    document.querySelector(".contact__btn").disabled = true;
  }

  function resetStatus() {
    setTimeout(() => {
      setStatus(undefined);
    }, 4000);
  }

  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "Annanoah.GB@mail.com",
    message: "",
    reply_to: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();

    send(
      "service_dzm2dwe",
      "template_5i0c83q",
      toSend,
      "user_FOdtXX8OhZLylDuZAR0MF"
    )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setStatus({ type: "success" });
        setToSend({
          from_name: "",
          to_name: "Annanoah.GB@mail.com",
          message: "",
          reply_to: "",
        });
        resetStatus();
        onExpired();
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setStatus({ type: "error", err });
        resetStatus();
        onExpired();
      });
  };

  const handleChange = (e) => {
    setToSend({
      ...toSend,
      [e.target.name]: e.target.value,
      to_name: "Annanoah.GB@mail.com",
    });
  };

  useEffect(() => {
    if (props.isKitten) {
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.add("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.add("menu__nav-link--yellow");
    } else if (!props.isKitten) {
      document.querySelectorAll(".menu__nav-link--kittens").forEach((item) => {
        item.textContent = "Kittens";
      });
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.remove("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.remove("menu__nav-link--yellow");
    }
  }, [props.isKitten]);
  return (
    <>
      <Helmet>
        <title>
          Contact | Annanoah Ragdoll Cattery GB - Oxfordshire United Kingdom
        </title>
        <link rel="canonical" href="https://annanoahcattery.com/contact" />
      </Helmet>
      <main className="contact">
        <h1 className="contact__title">Contact</h1>
        <div className="contact__wrapper">
          <h2 className="contact__text contact__text--subtitle">
            Send Us a Message
          </h2>
          <form id="contact__form" onSubmit={onSubmit}>
            <div className=" contact__form-group">
              <label htmlFor="name">Name:</label>
              <input
                required
                type="text"
                name="from_name"
                value={toSend.from_name}
                className="contact__form-control"
                placeholder="Name"
                onChange={handleChange}
              />
            </div>
            <div className="contact__form-group">
              <label htmlFor="exampleInputEmail1">Email:</label>
              <input
                required
                type="email"
                name="reply_to"
                className="contact__form-control"
                aria-describedby="emailHelp"
                value={toSend.reply_to}
                placeholder="Email"
                onChange={handleChange}
              />
            </div>
            <div className="contact__form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                className="contact__form-control"
                rows="5"
                name="message"
                value={toSend.message}
                placeholder="Type your message here..."
                onChange={handleChange}
              ></textarea>
            </div>
            <div>
              <Suspense fallback={<></>}>
                <ReCAPTCHA
                  className="contact__recaptcha"
                  sitekey={REACT_APP_RECAPTCHA_KEY}
                  onChange={onChange}
                  onExpired={onExpired}
                />
              </Suspense>
            </div>
            <button type="submit" className="contact__btn" disabled>
              Submit
            </button>
            {status?.type === "success" && (
              <p className="contact__success">"Your message has been sent."</p>
            )}
            {status?.type === "error" && (
              <p className="contact__unsuccess">
                "Sending the message failed. Try again."
              </p>
            )}
          </form>
          <div className="contact__img-container-right">
            <picture className="about-us__picture">
              <source media="(min-width: 800px)" srcSet={contact3M} />
              <source media="(min-width: 400px)" srcSet={contact3S} />
              <img
                className="contact__img-right contact__img"
                src={contact3XS}
                alt="cat "
              />
            </picture>
          </div>
          <div className="contact__img-container-left ">
            <picture className="about-us__picture">
              <source media="(min-width: 800px)" srcSet={contact4M} />
              <source media="(min-width: 600px)" srcSet={contact4S} />
              <img
                className="contact__img-left contact__img"
                src={contact41px}
                alt="cat "
              />
            </picture>
          </div>
          <div className="contact__data">
            <div className="contact__data-item-wrapper">
              <div className="contact__data-item">
                <FontAwesomeIcon
                  icon={faMapMarker}
                  className="contact__data-icon"
                />
                <p className="contact__data-text">
                  Brize Norton , Carterton , Oxfordshire United Kingdom
                </p>
              </div>
              <div className="contact__data-item">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="contact__data-icon"
                />
                <p className="contact__data-text">
                  <PhoneNumber
                    number="+4407395660201"
                    target="_blank"
                    isLinked={true}
                  >
                    +44 07395660201
                  </PhoneNumber>
                </p>
              </div>
              <div className="contact__data-item">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="contact__data-icon"
                />
                <p className="contact__data-text">
                  <Link
                    to={{ pathname: "mailto:annanoah.gb@mail.com" }}
                    target="_blank"
                  >
                    annanoah.gb@mail.com
                  </Link>
                </p>
              </div>
              <div className="contact__data-item">
                <FontAwesomeIcon
                  icon={faFacebookF}
                  className="contact__data-icon"
                />
                <p className="contact__data-text">
                  <Link
                    to={{
                      pathname:
                        "https://www.facebook.com/Annanoah-GB-Ragdoll-Cattery-102075491924910",
                    }}
                    target="_blank"
                  >
                    Annanoah GB - Ragdoll Cattery
                  </Link>
                </p>
              </div>
              <div className="contact__data-item">
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="contact__data-icon"
                />
                <p className="contact__data-text">
                  <Link
                    to={{
                      pathname:
                        "https://www.instagram.com/annanoah_ragdoll_cattery/",
                    }}
                    target="_blank"
                  >
                    annanoah_ragdoll_cattery
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Contact;
